const customSelect = function() {
  /* Look for any elements with the class "custom-select": */
  let selectItems = document.getElementsByClassName("custom-select");
  for (let i = 0; i < selectItems.length; i++) {
    let selElmnt = selectItems[i].getElementsByTagName("select")[0];
    /* For each element, create a new DIV that will act as the selected item: */
    let selectWrap = document.createElement("DIV");
    selectWrap.setAttribute("class", "select-selected");
    selectWrap.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    if (selElmnt.dataset.placeholder) selectWrap.innerHTML = selElmnt.dataset.placeholder;
    selectItems[i].appendChild(selectWrap);
    /* For each element, create a new DIV that will contain the option list: */
    let selectDrop = document.createElement("DIV");
    selectDrop.setAttribute("class", "select-items select-hide");
    for (let j = 0; j < selElmnt.length; j++) {
      /* For each option in the original select element,
      create a new DIV that will act as an option item: */
      let selectItem = document.createElement("DIV");
      selectItem.innerHTML = selElmnt.options[j].innerHTML;
      selectItem.addEventListener("click", function(e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        for (i = 0; i < s.length; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            for (k = 0; k < y.length; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      selectDrop.appendChild(selectItem);
    }
    selectItems[i].appendChild(selectDrop);
    selectWrap.addEventListener("click", function(e) {
      if(!this.parentNode.classList.contains('disabled')) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      }
    });
  }
}

function closeAllSelect(elmnt) {
  /* A function that will close all select boxes in the document,
  except the current select box: */
  var x, y, i, arrNo = [];
  let selectItems = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < selectItems.length; i++) {
    if (arrNo.indexOf(i)) {
      selectItems[i].classList.add("select-hide");
    }
  }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener("click", closeAllSelect);

export {customSelect};