const details = function() {
	function details(e) {
		const details = e;
		const detailsButton = details.querySelector('.details__button');
		const detailsBody = details.querySelector('.details__body');

		if (detailsButton) {
			detailsButton.addEventListener('click', function() {
				if (!this.classList.contains('details__button_active')) {
					this.classList.add('details__button_active');
					detailsBody.classList.add('details__body_active');
				} else if (this.classList.contains('details__button_active')) {
					this.classList.remove('details__button_active');
					detailsBody.classList.remove('details__body_active');
				}
			})
		}
	}

	const detailss = document.querySelectorAll('.details');
	for (let i = 0; i < detailss.length; i++) {
		details(detailss[i]);
	}
}

export {details};
