const alert = function() {
	const alerts = document.querySelectorAll('[data-alert]');

	if (alerts) {
		for (let i = 0; i < alerts.length; i++) {
      alerts[i].addEventListener('click', (e) => {
        let alertId = e.target.getAttribute('data-alert');
        document.getElementById(alertId).classList.add('open');
      });
      let alertId = alerts[i].getAttribute('data-alert');
      let thisAlert = document.getElementById(alertId);
      let alertClose = thisAlert.querySelector('.alert-close');
      alertClose.addEventListener('click', (e) => {
        thisAlert.classList.remove('open');
      });
		}
	}
}

export {alert};