const navigation = function() {
	const menuFormButton = document.querySelector('.menu-form__button');
	/* const navigation = document.querySelector('.navigation'); */
	const pageWrapper = document.querySelector('.page-wrapper');
	const body = document.querySelector('body');
	const header = document.querySelector('header');

	const dropdownUser = header.querySelector('.menu-user');

	const dropdownLanguage = document.querySelector('.footer-language');

	if (menuFormButton) {
		menuFormButton.addEventListener('click', function() {
			if (!this.classList.contains('menu-form__button_active')) {
				this.classList.add('menu-form__button_active');
				/* navigation.style.transform = 'translate(0, 0)'; */
				pageWrapper.style.transform = 'translate(300px, 0)';
				body.style.overflowX = 'hidden';
				header.classList.add('header_light');
			} else if (this.classList.contains('menu-form__button_active')) {
				this.classList.remove('menu-form__button_active');
				/* navigation.style.transform = 'translate(-300px, 0)'; */
				pageWrapper.style.transform = 'translate(0, 0)';
				setTimeout(function() {
					body.style.overflowX = 'auto';
				}, 300);
				header.classList.remove('header_light');
			}
		})
	}
	if (dropdownUser) {
		const dropdownUserName = dropdownUser.querySelector('.menu-user__name');
		dropdownUserName.addEventListener('click', function() {
			if (!dropdownUser.classList.contains('open')) {
				dropdownUser.classList.add('open');
			} else if (dropdownUser.classList.contains('open')) {
				dropdownUser.classList.remove('open');
			}
		})
	}
	if (dropdownLanguage) {
		const dropdownLanguageName = dropdownLanguage.querySelector('.footer-language__selected');
		dropdownLanguageName.addEventListener('click', function() {
			if (!dropdownLanguage.classList.contains('open')) {
				dropdownLanguage.classList.add('open');
			} else if (dropdownLanguage.classList.contains('open')) {
				dropdownLanguage.classList.remove('open');
			}
		})
	}
}

export {navigation};
