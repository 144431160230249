
function uploadFile() {
	let inputFile = document.querySelectorAll('input[type="file"]');

	[].forEach.call(inputFile, (input)=> {
		input.addEventListener('change', function() {
			uploadFiles(event);
		});
	});

}

function uploadFiles(event) {
	let input = event.target;
	let HTMLFiles = '';
	let formUpload = input.closest('.regiatration-form-upload');
	var newFileList = Array.from(input.files);

	if ('files' in input) {
		if (newFileList.length == 0) {
			HTMLFiles = '';
		} else {
			for (var i = 0; i < newFileList.length; i++) {
				var file = newFileList[i];
				if ('name' in file) {
					HTMLFiles += '<div class="uploaded-files-item" data-name="' + file.name + '">' + 
								'<div class="uploaded-files-file"><span>' + file.name + '</span></div>' +
								'<svg class="uploaded-files-close" width="29" height="28" viewBox="0 0 29 28"><g fill="none" fill-rule="evenodd"><path fill="#EB0000" d="M13.988 4.5c1.37 0 2.5.987 2.654 2.253h3.073c1.244 0 2.255.963 2.255 2.148v.11c0 .905-.591 1.68-1.425 1.995v10.147c0 1.185-1.012 2.148-2.255 2.148H9.687c-1.244 0-2.255-.963-2.255-2.148V11.006c-.834-.316-1.426-1.09-1.426-1.995V8.9c0-1.185 1.012-2.148 2.256-2.148h3.073c.153-1.266 1.284-2.253 2.653-2.253zm5.487 6.659H8.501v9.994c0 .623.532 1.13 1.186 1.13h8.603c.653 0 1.185-.507 1.185-1.13V11.16zm-8.354 3.067c.295 0 .535.228.535.51v5.735c0 .28-.24.509-.535.509a.522.522 0 0 1-.535-.51v-5.734c0-.282.24-.51.535-.51zm2.867 0c.296 0 .535.228.535.51v5.735c0 .28-.24.509-.535.509a.523.523 0 0 1-.534-.51v-5.734c0-.282.239-.51.534-.51zm2.868 0c.295 0 .534.228.534.51v5.735c0 .28-.24.509-.534.509a.523.523 0 0 1-.535-.51v-5.734c0-.282.24-.51.535-.51zm2.86-6.455H8.261c-.654 0-1.186.507-1.186 1.13v.11c0 .622.532 1.13 1.186 1.13h11.453c.654 0 1.186-.508 1.186-1.13V8.9c0-.623-.532-1.13-1.186-1.13zM13.987 5.52c-.778 0-1.429.531-1.573 1.234h3.146c-.143-.703-.794-1.234-1.573-1.234z"/></svg>' +
							'</div>';
				}
			}
		}
	}
	formUpload.querySelector('.uploaded-files').innerHTML = HTMLFiles;
	formUpload.classList.add('uploaded');

	let closeFile = document.querySelectorAll('.uploaded-files-close');
	[].forEach.call(closeFile, (close)=> {
		close.addEventListener('click', function() {
			removeFile(close, newFileList);
		});
	});
}
function removeFile(close, newFileList) {
	let uploadedFilesItem = close.closest('.uploaded-files-item');
	let fileName = uploadedFilesItem.dataset.name;
	let formUpload = uploadedFilesItem.closest('.form-upload');
	//let input = formUpload.querySelector('input[type="file"]');

	for (var i = 0; i < newFileList.length; ++i) {
		if (newFileList[i].name === fileName)
			newFileList.splice(i,1);
	}
	if (newFileList.length == 0)
		formUpload.classList.remove('uploaded');

	uploadedFilesItem.parentNode.removeChild(uploadedFilesItem);
}

export {uploadFile};
