const tabbed = function() {
	function tabbed(e) {
		const tabbed = e;
		const tablist = tabbed.querySelector('.tabbed__tablist');
		const tabs = tablist.querySelectorAll('.tabbed__link');
		const panels = tabbed.querySelectorAll(':scope > .details__body > .tabbed__tabpanel');
		const hash = window.location.hash;

		const switchTab = (oldTab, newTab) => {
			newTab.focus();
			newTab.removeAttribute('tabindex');
			newTab.setAttribute('aria-selected', 'true');
			oldTab.removeAttribute('aria-selected');
			oldTab.setAttribute('tabindex', '-1');
			let index = Array.prototype.indexOf.call(tabs, newTab);
			let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
			panels[oldIndex].hidden = true;
			panels[index].hidden = false;
		}

		Array.prototype.forEach.call(tabs, (tab, i) => {
			tab.addEventListener('click', e => {
				e.preventDefault();
				let currentTab = tablist.querySelector('[aria-selected]');
				if (e.currentTarget !== currentTab) {
					switchTab(currentTab, e.currentTarget);
				}
			});

			tab.addEventListener('keydown', e => {
				let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
				let dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
				if (dir !== null) {
					e.preventDefault();
					dir === 'down' ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
				}
			});
		});

		Array.prototype.forEach.call(panels, (panel, i) => {
			panel.hidden = true;
		});

		let indexActive = 0;
		if (hash) {
			let hashElem = document.querySelector(hash);
			indexActive = Array.prototype.indexOf.call(panels, hashElem);
		}
		tabs[indexActive].removeAttribute('tabindex');
		tabs[indexActive].setAttribute('aria-selected', 'true');
		panels[indexActive].hidden = false;
	}

	const tabs = document.querySelectorAll('.tabbed');
	for (let i = 0; i < tabs.length; i++) {
		tabbed(tabs[i]);
	}
}

export {tabbed};
