import 'focus-visible'; // Полифил для :focus-visible

import {ui} from './ui';
import {menu} from './menu';
import {overview} from './overview';

document.addEventListener('DOMContentLoaded', function() {
	ui();
	menu();
	overview();
})
