import {alert} from './alert';
import {tabbed} from './tabbed';
import {details} from './details';
import {accordion} from './accordion';
import {toggleButton} from './toggleButton';
import {tableResponsive} from './table-responsive';
import {customSelect} from './custom-select';
import {uploadFile} from './upload-file';
import {customNumber} from './number';

const ui = function() {
	alert();
	tabbed();
	details();
	accordion();
	toggleButton();
	tableResponsive();
	customSelect();
	uploadFile();
	customNumber();
}

export {ui};
