const tableResponsive = function() {
	const table = document.querySelectorAll('.tableResponsive');

	for (let i = 0; i < table.length; i++) {
		if (table[i]) {
			table[i].addEventListener('scroll', function(e) {
				console.log(e.target.scrollLeft);
				if (e.target.scrollLeft > 0) {
					e.target.classList.add('is-scroll');
				}
				else e.target.classList.remove('is-scroll');
			})
		}
	}

}

export {tableResponsive};
