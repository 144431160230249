const accordion = function() {
	function accordion(e) {
		const _accordionItem = e;
		const _accordionTitle = _accordionItem.querySelector('.accordion-title');

		if (_accordionTitle) {
			_accordionTitle.addEventListener('click', function() {
				if (!_accordionItem.classList.contains('is-open')) {
					const accordionsItem = document.querySelectorAll('.accordion-item');
					for (let i = 0; i < accordionsItem.length; i++) {
						accordionsItem[i].classList.remove('is-open');
					}
					_accordionItem.classList.add('is-open');
				} else if (_accordionItem.classList.contains('is-open')) {
					_accordionItem.classList.remove('is-open');
				}
			});
		}
	}

	const _accordion = document.querySelector('.accordion');
	if (_accordion) {
		const _accordionItem = _accordion.querySelectorAll('.accordion-item');
		for (let i = 0; i < _accordionItem.length; i++) {
			accordion(_accordionItem[i]);
		}
	}
}

export {accordion};
