const overviewAssets = function() {

	const overviewAssets = document.querySelector('.overview-assets');

	if (overviewAssets) {
		overviewAssets.addEventListener('scroll', function(e) {
			if (e.target.scrollLeft > 0) {
				e.target.classList.add('is-scroll');
			}
			else e.target.classList.remove('is-scroll');
		})
	}

}

export {overviewAssets};
