function customNumber() {
    let inputNumber = document.querySelectorAll('input[type="number"]');
    
	[].forEach.call(inputNumber, (input)=> {
        let formNumber = input.closest('.regiatration-form-number');

        let upArrow = document.createElement("div");
        upArrow.classList.add("number-button", "number-button--up");
        upArrow.addEventListener('click', function() {
            let input = this.closest('.regiatration-form-number').querySelector('input[type="number"]');
            if (!input.value) input.value = 0;
            input.value = parseInt(input.value)+1;
        });
        formNumber.appendChild(upArrow);

        let downArrow = document.createElement("div");
        downArrow.classList.add("number-button", "number-button--down");
        downArrow.addEventListener('click', function() {
            let input = this.closest('.regiatration-form-number').querySelector('input[type="number"]');
            if (!input.value) input.value = 0;
            input.value = parseInt(input.value)-1;
        });
        formNumber.appendChild(downArrow);
    });
}

export {customNumber};