const overviewModal = function() {
	function overviewOpenModal(e) {
		const item = e;
		const itemWithdraw = item.querySelector('.withdraw');
		const overviewModal = document.querySelector('.overview-modal');
		const overviewModalClose = overviewModal.querySelector('.overview-modal-close');

		if (itemWithdraw) {
			itemWithdraw.addEventListener('click', function() {
				overviewModal.classList.add('is-open');
			})
		}
		if (overviewModalClose) {
			overviewModalClose.addEventListener('click', function() {
				overviewModal.classList.remove('is-open');
			})
		}
	}

	const overviewAssets = document.querySelectorAll('.overview-assets-row');
	for (let i = 0; i < overviewAssets.length; i++) {
		overviewOpenModal(overviewAssets[i]);
	}
}

export {overviewModal};
