const toggleButton = function() {
	const toggle = document.querySelectorAll('.toggle-button');

	if (toggle) {
		for (let i = 0; i < toggle.length; i++) {
			toggle[i].addEventListener('click', (e) => {
				let pressed = e.target.getAttribute('aria-pressed') === 'true';
				e.target.setAttribute('aria-pressed', String(!pressed));
			});
		}
	}
}

export {toggleButton};
